import React from 'react';
import Zoom from "react-reveal/Zoom";
import ImageZoom from 'react-medium-image-zoom';

const Info = () => {
  return (
    <div className="bck_black">
        <div className="center_wrapper pricing_section">
            <h2>Portfolio</h2>

     
          
      <div className="center_wrapper">
        <div className="vn_wrapper">
    
        <Zoom duration={500}>
            <div className="vn_item">
              
                <div className="vn_inner">
                  
                 <div className="kult">
   
                      <ImageZoom
                        image={{
                          src: "http://bugpro.by/img/kult/1p.jpg",
                          alt: 'BUDYNKI RELIGIJNE',
                          className: 'img',
                          style: {  width: '225px', height: '150px' }
                        }}
                        zoomImage={{
                          src: "http://bugpro.by/img/kult/1.jpg",
                          alt: 'BUDYNKI RELIGIJNE', 
                    }}
                      />
      <p className="p">RELIGIJNE</p>
      <p className="p2">budynki</p>
                </div>
                 
                </div>
              
            </div>
          </Zoom>

<Zoom duration={500} delay={500}>
            <div className="vn_item">
              <div className="vn_inner">
                   <div className="proizv">   
      <ImageZoom
        image={{
          src: "http://bugpro.by/img/obshestv/1p.jpg",
          alt: 'BUDYNKI PUBLICZNE',
          className: 'img',
           style: {  width: '225px', height: '150px' }
        }}
        zoomImage={{
          src: "http://bugpro.by/img/obshestv/1.jpg",
          alt: 'BUDYNKI PUBLICZNE'
        }}
      />
        <p className="p">PUBLICZNE</p>
        <p className="p2">budynki</p>
    </div>
                 
              </div>
            </div>
          </Zoom>

          <Zoom duration={500} delay={1000}>
            <div className="vn_item">
              <div className="vn_inner">
              <div className="obshestv">   
       <ImageZoom
        image={{
          src: "http://bugpro.by/img/proizv/2p.jpg",
          alt: 'PRODUKCYJNE zaklady',
          className: 'img',
           style: {  width: '225px', height: '150px' }
        }}
        zoomImage={{
          src: "http://bugpro.by/img/proizv/2.jpg",
          alt: 'PRODUKCYJNE zaklady'
        }}
      />
      
        <p className="p">PRODUKCYJNE</p> 
        <p className="p2">zaklady</p>
    </div>
                 
              </div>
            </div>
          </Zoom>

 <Zoom duration={500} delay={1500}>
            <div className="vn_item">
              <div className="vn_inner">
                 
                  
       <div className="zhylye">  
      <ImageZoom
        image={{
          src: "http://bugpro.by/img/zhylye/1p.jpg",
          alt: 'MIESZKALNE budynki',
          className: 'img',
          style: {  width: '225px', height: '150px' }
        }}
        zoomImage={{
          src: "http://bugpro.by/img/zhylye/1.jpg",
          alt: 'MIESZKALNE budynki'
        }}
      />
    <p className="p">MIESZKALNE </p> 
      <p className="p2">budynki</p>
    </div>

                 
              </div>
            </div>
          </Zoom>

          </div>
        </div>
      </div>
    </div>
  );
};


export default Info;