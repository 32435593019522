import React from 'react';
import Zoom from "react-reveal/Zoom";
import MyButton from '../Utils/myButton';

const Pricing = () => {
  const data = [
    {
//      prices: 100,
      positions: "Zarządzanie",
      desc: "- KOMPLEKSOWE zarządzanie budową  \n - KONSULTACJE w sprawie projektu i budowy \n - ROZWÓJ koncepcji architektonicznych i planowania (dokumentacja przedprojektowa)",
      linkto: "https://www.facebook.com/367203584048586/services/?service_id=2118442448213011",
      delay: 500
    },
    {
//      prices: 150,
      positions: "Projektowanie",
      desc:      
         "- ZAKLADY PRODUKCYJNE (uprawy grzybów, centra logistyczne, przechowywanie i przetwarzanie produktów roślinnych, warsztaty samochodowe, obróbka drewna, kompleksy hodowlane) \n- BUDYNKI PUBLICZNE (budynki biurowe, centra handlowe, budynki wielofunkcyjne, obiekty turystyczne i rekreacyjne, usługi drogowe)\n- BUDYNKI MIESZKALNE (wielokondygnacyjne budynki mieszkalne, parkingi wielopoziomowe)\n- BUDYNKI RELIGIJNE \n- KOTLOWNIE",
      linkto:
        "https://www.facebook.com/367203584048586/services/?service_id=2253137114696445",
      delay: 0
    },
    {
//      prices: 250,
      positions: "Techniczny nadzór",
      desc:
        "- NADZOR TECHNICZNY jest częścią procesu budowlanego, który obejmuje kontrolę i nadzór klienta nad zgodnością robót budowlanych i instalacyjnych, zastosowanych materiałów, produktów, konstrukcji i wyposażenia z wymaganiami obowiązujących przepisów technicznych oraz zatwierdzonej dokumentacji projektowej i kosztorysowej, a także akceptację i rejestrację wykonanych prac",
      linkto:
        "https://www.facebook.com/bugpro.by/",
      delay: 500
    }
  ];

    const showBoxes = () =>
      data.map((item, i) => (
        <Zoom delay={item.delay} key={i}>
          <div className="pricing_item">
            <div className="pricing_inner_wrapper">
              <div className="pricing_title">
                  <span>{item.positions}</span>
              </div>
              <div className="pricing_description">
                {item.desc}
              </div>
              
            </div>
          </div>
        </Zoom>
      ));
  return (
    <div className="bck_black">
      <div className="center_wrapper pricing_section">
        <h2>Usługi</h2>

        <div className="pricing_wrapper">{showBoxes()}</div>
      </div>
    </div>
  );
};

export default Pricing;