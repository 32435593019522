import React from "react";
import Fade from "react-reveal/Fade";

const Description = () => {
  return (
    <Fade>
      <div className="center_wrapper">
        <h2>Korzyści</h2>
        <div className="highlight_description">
          Zawierając umowę z BUG-PROJECT, uzyskujesz przede wszystkim gwarancję wykonania pracy na czas z jakością sprawdzoną od lat. Do zalet naszej firmy należą również: wykwalifikowani specjaliści, indywidualne podejście do każdego klienta oraz konkurencyjna cena.
        </div>
      </div>
    </Fade>
  );
};

export default Description;
